import { HttpClient } from '@angular/common/http';
import { map, catchError, timeout } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError, of } from 'rxjs';
import { Globals } from './globals';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
// import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    // , private deviceService: DeviceDetectorService
    constructor(private http: HttpClient, private globals: Globals, private toastrService: NbToastrService, private router: Router) {
    }

    // fp = this.deviceService.device + "_" + this.deviceService.os + "_" + this.deviceService.os_version + "_" + this.deviceService.browser + "_"
    //     + this.deviceService.browser_version;

    getRequestUrl(url): string {
        return this.globals.baseUrl + url;
    }

    getHeaders() {
        return {
            'Authorization': "bearer " + this.globals.getCurrentUserToken(),
            // 'Authorization': "bearer VVgaAS9bmdmb/0HW5YvfIbrEzY79dmTmCHCSx7oy0XOZAfw1pRmvoQ8Az8COosNUcGVD7PaQLeUaEFtHZPAU3k6wUy17CzrqLhSfMZoRnQs/vHAyFA7cFXVXYSbpd3O99Gaw8o2EAslEve8umoxFrOHbhHQp+Vq+KgCWlJZeLgO1xgI4oglK25WrpzTYA4TdToMaMPWw6LoXj3WqvX+tzgsLQQLLFEogg6nMfO7CFwPnmj5TM+vyh+RUi4MH0g4lIo0hIIE0nFyP7tp/8tJLb06gp2e182y/AjqVQiKM2HZTQBQjCGE/bbqcmg+htjqJ+uKLO7q/eipWgLkQP7gLNfnwcllE/ubB5kpLtQYwCpjAl6On/l0nIW7Rd7MzxZfG",
            // 'program-id': this.globals.settings.programId.toString(),
            'client-language': this.globals.getCurrentUserLanguage(),
            // 'fp': this.fp,
            'partner-id': this.globals.getCurrentUser() ? this.globals.getCurrentUser().PartnerId.toString() : '0',
            'url': this.globals.currentPartner ? this.globals.currentPartner.Domain : window.location.toString(),
        };
    }

    getPostHeaders() {
        let user = this.globals.getCurrentUser();
        let partnerId = 0;
        if (user)
            partnerId = user.PartnerId;
        return {
            'Authorization': "bearer " + this.globals.getCurrentUserToken(),
            'Content-Type': 'application/json; charset=UTF-8',
            'client-language': this.globals.getCurrentUserLanguage(),
            // 'fp': this.fp,
            // 'program-id': this.globals.settings.programId.toString(),
            'partner-id': partnerId.toString(),
            'url': this.globals.currentPartner ? this.globals.currentPartner.Domain : window.location.toString(),
        };
    }

    getRequest(url, requestParams) {
        if (!this.globals.isUserLoggedIn()) {
            this.router.navigate(['my-auth/login']);
            return of().pipe(map(data => {
            }));
        }
        var headers = this.getHeaders();
        // this.globals.settings.isLoaderVisible = true;
        return this.http.get<any>(this.getRequestUrl(url), { headers: headers, params: requestParams })
            .pipe(
                timeout (this.globals.HttpTimeOut),
                map(data => {
                    // this.globals.settings.isLoaderVisible = false;
                    if (data.ResponseStatus && (data.ResponseStatus.Status < 200 || data.ResponseStatus.Status > 399))
                        this.showToast(data.ResponseStatus.Message);
                    return data
                }),
                catchError((error) => this.errorHandler(error)));
    }

    getRequestWitheoutUserLogin(url, requestParams) {
        var headers = this.getHeaders();
        // this.globals.settings.isLoaderVisible = true;
        return this.http.get<any>(this.getRequestUrl(url), { headers: headers, params: requestParams })
            .pipe(
                timeout (this.globals.HttpTimeOut),
                map(data => {
                    // this.globals.settings.isLoaderVisible = false;
                    if (data.ResponseStatus && (data.ResponseStatus.Status < 200 || data.ResponseStatus.Status > 399))
                        this.showToast(data.ResponseStatus.Message);
                    return data
                }),
                catchError((error) => this.errorHandler(error)));
    }

    getRequestOfObjectId(url, objectId) {
        if (!this.globals.isUserLoggedIn()) {
            this.router.navigate(['my-auth/login']);
            return;
        }
        var headers = this.getHeaders();
        url += "/" + objectId;
        return this.http.get<any>(this.getRequestUrl(url), { headers: headers })
            .pipe(
                timeout (this.globals.HttpTimeOut),
                map(data => {
                    // this.globals.settings.isLoaderVisible = false;
                    if (data.ResponseStatus && (data.ResponseStatus.Status < 200 || data.ResponseStatus.Status > 399))
                        this.showToast(data.ResponseStatus.Message);
                    return data
                }),
                catchError((error) => this.errorHandler(error)));
    }

    postRequest(url: string, requestParams: any) {
        if (!this.globals.isUserLoggedIn() && !this.router.url.includes('my-auth/login')) {
            return of().pipe(map(data => {
                this.router.navigate(['my-auth/login']);
            }));
        }
        let headers = this.getPostHeaders();
        // this.globals.settings.isLoaderVisible = true;
        return this.http.post<any>(this.getRequestUrl(url), requestParams, { headers: headers })
            .pipe(
                timeout (this.globals.HttpTimeOut),
                map(data => {
                    // this.globals.settings.isLoaderVisible = false;
                    if (data.ResponseStatus && (data.ResponseStatus.Status < 200 || data.ResponseStatus.Status > 399))
                        this.showToast(data.ResponseStatus.Message);
                    return data
                }),
                catchError((error) => this.errorHandler(error)));
    }

    // this.showToast('top-right','success');
    showToast(message) {
        this.toastrService.show(
            '',
            message,
            { status: 'warning', duration: 5000 });
    }

    postFile(url: string, fileToUpload: File) {
        const formData: FormData = new FormData();
        let headers = this.getHeaders();
        formData.append('file', fileToUpload, fileToUpload.name);
        return this.http
            .post<any>(this.getRequestUrl(url), formData, { headers: headers }).pipe(
                timeout (this.globals.HttpTimeOut),
                map(data => {
                    return data;
                }),
                catchError((error) => this.errorHandler(error)));
    }

    errorHandler(error: HttpErrorResponse) {
        if (error.status == 401)
            this.globals.onNeedToReconnect.next();

        if (this.globals)
            this.globals.settings.isLoaderVisible = false;
        return throwError(error.message || "Server Error");
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApiUrls {
    debatesOfProgramUrl = "panel/all";
    debateUrl = "panel/get";
    usersOfDebatesUrl = "panel/users";
    positionsOfDebatesUrl = "panel/positions"
    positionUrl = "panel/position"
    argumentsOfPositionUrl = "panel/PositionArguments"
    sendArgumentUrl = "panel/NewPositionArgument"
    updatePositionOfArgumentUrl = "panel/UpdatePositionOfArgument"
    sendDebateUrl = "panel/NewPanel"
    sendPostionUrl = "panel/NewPanelPosition"
    sendPositionFeedbackUrl = "panel/PositionFeedback"
    isUserInPanelUrl = "panel/IsUserInPanel"
    getExpertices = "UserProfile/Hats"
    likePostUrl = "panel/PositionArgumentFeedback"
    postsOfDiscussionUrl = "Discussion/Expressions"
    postsOfDebateUrl = "panel/PositionArgumentsOfPanel"
    opinionLeaders = "Panel/Users"
    relatedDebates = "Panel/RelatedDiscussions"
    searchPanel = "Panel/Search"
    panelColors = "Panel/Colors"
    panelMedia = "Media/Panel"
    sendPostUrl = "Discussion/NewExpression"
    sendHat = "Panel/Recommendation"

    getSelfExperticess = "UserProfile/Get"
    userSummaryUrl = "UserProfile/get"
    userProfileUrl = "UserProfile/current"
    addExpertice = "UserProfile/AddHat"
    deleteExpertice = "UserProfile/DeleteHat"
    positionLikedByUserUrl = "User/GetPositionLikedByUser"
    updateUserSettingsUrl = "UserProfile/UpdateSettings"
    UpdateUserImageUrl = "UserProfile/UpdateImage"
    RegisterEmailUser = "Auth/RegisterEmailUser"
    LoginByEmail = "Auth/LoginByEmail"
    RegisterBy3rdPartyAuth = "Auth/Register3rdPartyUser"
    getTokenUrl = "Auth/Token"
    forgotPasswordUrl = "Auth/ForgotPassword"

    resourcesUrl = "resources/get"

    nlpPredictDebateUrl = "nlp/PredictPanel"
    nlpPredictPositionUrl = "nlp/PredictPanelPosition"
    nlpPredictUrlPanelsUrl = "nlp/PredictUrlPanels"

    publishers = "publisher/get"

    getCountriesUrl = "Location/Countries"

    followUserUrl = "Follow/Offer"
    unfollowUserUrl = "Follow/Cancel"

    userDebates = "User/GetUserPanels"
    userComments = "User/GetUserArguments"
    userFollowers = "Follow/AllFollowers"
    userFollowings = "Follow/AllFollowing"
    userLikedUrl = "User/GetArgumentsLikedByUser"

    userNotificationsCounter = "Notification/NotificationsCounters"
    userCommercialOffers = "Notification/Messages"
    userCommentsNotification = "Notification/Replies"
    userFollowersNotification = "Notification/Followers"
    userLikesNotification = "Notification/Likes"
    userNotificationsViewed = "Notification/Viewed"

    debateByBlog = "Blog/HomepagePanels"
    debateListByBlog = "Blog/HomepagePanelList"
    debateCategories = "Blog/Categroies"
    DebatesByCategory = "Blog/CategoryPanels"
    leadingComments = "Blog/LeadingComments"
}