import { Injectable } from '@angular/core';
import { PartnerModel } from '../models/partner';
import { AddPartnerRequest } from '../models/requests/addPartnerRequest';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { PermissionsToPartnerRequest } from '../models/requests/permissionsToPartnerRequest';
import { PartnerResponse, ResourcesIdAndNameResponse, BaseResponse, UpdateImageResponse } from '../models/responses/Responses';

@Injectable({
    providedIn: 'root'
})

export class PartnerService {

    constructor(private api: ApiService) {
    }
    // GET
    GetPartner(): Observable<PartnerResponse> {
        return this.api.getRequest("Partner/GetPartner", { "administrationMode": 1 }).pipe(
            map(data => { return data; }));
    }
    GetPartnersOfUser(): Observable<ResourcesIdAndNameResponse> {
        return this.api.getRequest("Partner/GetPartnersOfUser", null).pipe(
            map(data => { return data; }));
    }
    GetAllPartners(): Observable<ResourcesIdAndNameResponse> {
        return this.api.getRequest("Partner/GetAllPartners", null).pipe(
            map(data => { return data; }));
    }
    //POST
    AddPartner(addPartnerRequest: AddPartnerRequest): Observable<BaseResponse> {
        return this.api.postRequest("Partner/AddPartner", addPartnerRequest).pipe(
            map(data => { return data; }));
    }
    EditPartner(partner: PartnerModel): Observable<BaseResponse> {
        return this.api.postRequest("Partner/EditPartner", partner).pipe(
            map(data => { return data; }));
    }
    AddPermissionsToPartner(permissions: PermissionsToPartnerRequest): Observable<BaseResponse> {
        return this.api.postRequest("Partner/AddPermissionsToPartner", permissions).pipe(
            map(data => { return data; }));
    }
    DeletePermissionsOfPartner(permissions: PermissionsToPartnerRequest): Observable<BaseResponse> {
        return this.api.postRequest("Partner/DeletePermissionsOfPartner", permissions).pipe(
            map(data => { return data; }));
    }
    EditPermissionsOfPartner(permissions: PermissionsToPartnerRequest): Observable<BaseResponse> {
        return this.api.postRequest("Partner/EditPermissionOfPartner", permissions).pipe(
            map(data => { return data; }));
    }
    UpdateImage(file: File): Observable<UpdateImageResponse> {
        return this.api.postFile("Partner/UpdateImage", file).pipe(
            map(data => { return data; }));
    }
}