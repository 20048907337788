export class LocaleResources {
    resources: Map<string, string>;

    constructor(resourcesArray: ILocaleResourceItem[]) {
        let resourcesDict: Map<string, string> = new Map<string, string>();
        if (resourcesArray) {
            resourcesArray.forEach(x => resourcesDict.set(x.Key, x.Value));
        }
        this.resources = resourcesDict;
    }

    get(key: string): string {
        let resourceValue = this.resources.get(key);
        if (!resourceValue)
            resourceValue = key;
        return resourceValue;
    }
}

export interface ILocaleResourceItem {
    Key: string;
    Value: string;
    DefaultValue: string
}

//--------- Old LocaleResources ----------------
// export class LocaleResources {
//     resources: ILocaleResourceItem[];

//     constructor(resourcesArray: ILocaleResourceItem[]) {
//         let list: ILocaleResourceItem[] = []
//         if (resourcesArray) {
//             resourcesArray.forEach((x => {
//                 list.push({ Key: x.Key, Value: x.Value, DefaultValue: x.DefaultValue });
//             }));
//         }
//         this.resources = list;
//     }
//     get(key: string): string {
//         let resourceItem = this.resources.find(x => x.Key == key);
//         if (!resourceItem)
//             return key;
//         let val = resourceItem.Value;
//         if (!val)
//             val = resourceItem.DefaultValue
//         return val;
//     }
// }