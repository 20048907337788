import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbResetPasswordComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { ResponseStatus } from '../../../../models/responseStatus';
import { AuthService } from '../../../../services/auth.service';
import { Globals } from '../../../../services/globals';

declare var grecaptcha: any;
@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent extends NbResetPasswordComponent implements OnInit {
  @ViewChild('recaptcha', { static: false }) recaptcha;

  constructor(
    public globals: Globals,
    private authService: AuthService,
    service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) options: {},
    cd: ChangeDetectorRef, router: Router
  ) {
    super(service, options, cd, router);
  }
  isLoadView: boolean = false;
  newPass: string = '';
  approvePassword: string = '';
  recaptchaToken: string = '';
  recaptchaValid = false;
  userCode;
  userId;
  ngOnInit(): void {
    this.userCode = this.getUrlParameter('sumit_code');
    this.userId = this.getUrlParameter('sumit_userid');
    if (this.userCode && this.userId) {
      this.isEmailVerificationCodeValid();
    }
  }
  getUrlParameter(sParam) {
    var sPageURL = window.location.hash.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  }
  isEmailVerificationCodeValid() {
    this.authService.IsEmailVerificationCodeValid(this.userCode, this.userId).subscribe(res => {
      if (this.globals.isResponseSuccess(res.ResponseStatus)) {
        this.isLoadView = true;
        setTimeout(() => {
          this.initRecaptchaScript();
        }, 1000);
      }
    });
  }
  newPassword() {
    if (this.newPass == this.approvePassword) {
      this.authService.NewPassword(this.userCode, this.userId, this.newPass, this.recaptchaToken).subscribe(res => {
        if (this.globals.isResponseSuccess(res.ResponseStatus)) {
          window.location.replace(res.Url);
        }
      })
    }
  }
  initRecaptchaScript() {
    this.executeRecaptcha();
  }
  reCaptchaCallback(response) {
    if (response !== '') {
      this.recaptchaValid = true;
      this.recaptchaToken = response;
    } else {
      this.recaptchaValid = false;
    }
  };
  executeRecaptcha(isReset = false) {
    if (isReset) {
      grecaptcha.reset(this.recaptcha);
      return;
    }
    let siteKey = '6LerzyoaAAAAAATYhEJa0RhVfFlnsifB01cJC43w';
    this.recaptcha = grecaptcha.render('recaptcha1', {
      'sitekey': siteKey,
      'callback': this.reCaptchaCallback.bind(this),
      'theme': 'light'
    });
  }
  resetCaptcha() {
    this.recaptchaValid = false;
    this.executeRecaptcha(true);
  }
}