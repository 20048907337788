import { Observable } from 'rxjs';
import { EmbeddingModel } from '../../models/embedding';
import { PageModel } from '../../models/page';
import { PagesResponse, PageResponse, EmbeddingResponse, EmbeddingsResponse, BaseResponse } from '../../models/responses/Responses';


export abstract class SmartTableData {
  abstract getData(): any[];
  abstract GetPagesOfPartner(pageNumber: number): Observable<PagesResponse>
  abstract GetPage(pageId: number): Observable<PageResponse>
  abstract GetEmbedding(Id: number): Observable<EmbeddingResponse>
  abstract GetEmbeddingsOfPartner(pageNumber: number): Observable<EmbeddingsResponse>
  abstract GetEmbeddingsOfPage(pageId: number, pageNumber: number): Observable<EmbeddingsResponse>
  abstract EditPage(page: PageModel): Observable<BaseResponse>
  abstract DeletePage(pageId: number): Observable<BaseResponse>
  abstract NewPage(page: PageModel): Observable<BaseResponse>
  abstract EditEmbedding(embedding: EmbeddingModel): Observable<BaseResponse>
  abstract DeleteEmbedding(embeddingId: number): Observable<BaseResponse>
  abstract NewEmbedding(embedding: EmbeddingModel): Observable<BaseResponse>



}
