import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Globals } from '../../../services/globals';
import { Router } from '@angular/router';
import { ResourceIdAndNameModel } from '../../../models/resourceIdAndNameModel';
import { UserModel } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { PartnerService } from '../../../services/partner.service';

export class myMenuItem extends NbMenuItem {
  id?: string;
}
@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: UserModel;
  partnersOfUser: ResourceIdAndNameModel[];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';
  currentPartner = '';

  userMenu: myMenuItem[] = [
    { title: this.globals.Resources.get('Profile'), id: 'profile' },
    { title: this.globals.Resources.get('LogOut'), id: 'logOut' },
    { title: this.globals.Resources.get('Partner'), id: 'partner' }];
  // userMenu: myMenuItem[] = [
  //   { title: 'Profile', id: 'profile' },
  //   { title: 'LogOut', id: 'logOut' },
  //   { title: 'Partner', id: 'partner' }];
  picture = 'assets/images/user.png';
  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    public globals: Globals,
    private router: Router,
    private partnerService: PartnerService) {
  }

  ngOnInit() {
    this.globals.onNeedToReconnect.subscribe(res => this.signOut());
    this.user = this.globals.getCurrentUser();
    this.partnersOfUser = this.user.Partners;
    this.currentTheme = this.themeService.currentTheme;
    if (this.partnersOfUser)
      this.currentPartner = this.partnersOfUser.find(p => p.ResourceId == this.user.PartnerId).ResourceName;
    this.user.DefaultLanguage ? this.globals.language = this.user.DefaultLanguage : null;
    if (this.user.AvatarUrl)
      this.picture = this.user.AvatarUrl;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.menuService.onItemClick()
      .subscribe((event) => {
        let temp: myMenuItem = event.item
        this.onContecxtItemSelection(temp.id);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }
  changeLanguage(language) {
    this.globals.language = this.user.DefaultLanguage = language;
    this.userService.EditUser(this.user).subscribe(res => {
      this.globals.setCurrentUser(this.user);
    });
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate([window.location.pathname]));
  }
  changePartner(partnerName: string) {
    this.currentPartner = partnerName;
    let user = this.globals.getCurrentUser();
    user.PartnerId = this.partnersOfUser.find(p => p.ResourceName == partnerName).ResourceId;
    this.globals.setCurrentUser(user);
    this.partnerService.GetPartner().subscribe(data => {
      this.globals.currentPartner = data.Partner;
      if (this.router.url == '/sumit-pages/admin/management/statistics') {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/sumit-pages/admin/management/statistics']);
      }
      else
        this.router.navigate(['sumit-pages/admin/management/statistics']);
    });
  }
  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  onContecxtItemSelection(id) {
    if (id === 'logOut') {
      this.signOut();
    }
    else
      if (id === 'profile') {
        this.router.navigate(['sumit-pages/admin/management/editProfile']);
      }
      else
        if (id === 'partner') {
          this.router.navigate(['/sumit-pages/admin/management/editPartner']);
        }
  }

  signOut() {
    this.globals.setCurrentUser(null);
    this.globals.currentPartner = null;
    this.router.navigate(['my-auth/login']);
  }
}