export enum EmbeddingMethodTypeEnum {
    SideButtonProgram = 0,
    InPageProgram = 1,
    AllPageBlog = 2,
}
export enum PageStatusEnum {
    None = 0,
    Active = 1,
    Paused = 2
}
export enum AuthTypeEnum {
    None = 0,
    Default = 1,
    NickName = 2,
    Phone = 100,
    Email = 101,
    Fecebook = 102,
    Google = 103,
}
export enum UserPriorityEnum {
    None = 0,
    Default = 1,
    Expert = 2
}
export enum InteractionEnum {
    None = 0,
    Liked = 1,
    Commented = 4
}
export enum ProgramContentCollectionMethodEnum {
    FromDb = 0,
    Auto = 1
}
export enum ArchiveSearchTargetEnum {
    All = 0,
    MineOnly = 1,
    ExternalOnly = 2
}
export enum DisplayingTypeEnum {
    PerMonth = 1,
    PerDay = 2
}
export enum ProgramApprovalMethodEnum {
    Auto = 0,
    Manual = 1,
    RegisteredUsersOnly = 2,
}
export enum ExpressionRelationType {
    None = 0,
    Descendent = 1,
    Parent = 2
}
export enum ArgumentPriorityEnum {
    None = 0,
    Normal = 1,
    Low = 2,
    High = 3
}
export enum PanelToProgramRelationTypeEnum {
    None = 0,
    Included = 1,
    Excluded = 2
}
export enum CommerceStatusEnum {
    None = 0,
    Active = 1,
    Suspended = 2
}
export enum BidStrategyEnum {
    None = 0,
    Smart = 1,
    Fixed = 2
}
export enum CampaignStatusEnum {
    None = 0,
    Active = 1,
    Suspended = 2,
    Deleted = 3
}
export enum CampaignSpendingModelEnum {
    None = 0,
    MonthlyFlight = 1,
    EntireCampaign = 2
}
export enum InteractiomModeEnum {
    Outbound = 1 << 0, //1
    Inbound = 1 << 1, //2
}
export enum ContentApprovalMethodEnum {
    None = 0,
    IncludeAllExcept = 1,
    ExcludeAllExcept = 2
}
export enum TemplateNameEnum {
    NewPartner = 1,
    Notification = 2,
    ForgotPassword = 3,
    NewFollower = 4,
    NewComment = 5,
    NewLike = 6,
    NewPositionFeedback = 7,
    EmailValidation = 8,
    DefaultPassword = 9,
    UserExternalRegistration = 10,
    NewCommentInDiscussion = 11,
    NewPrivateComment = 12,
    NewPanel = 13,
    NewPrivateCommentNoEmail = 14,
    NewPost = 15,
    NewRecommendation = 16,
    NewHeadToHeadPanel = 17,
    NewLikeMultiple = 18,
}
export enum ClickType {
    None = 0,
    AddPanel = 1,
    AddPanelStage1 = 2,
    AddPanelStage2 = 3,
    EditPanel = 4,
    InviteHeadToHead = 5,
    InviteHeadToHeadStage2 = 6,
    Follow = 7,
    ShareFacebook = 10,
    ShareWatsapp = 11,
    ShareEmail = 12,
    ShareTwitter = 13,
    UserProfileFollowing = 20,
    UserProfileFollowers = 21,
    // UserProfileComments = 22,
    // UserProfileDebates = 23,
    UserProfileExpertices = 24,
    // Panels = 30,
    // PanelPositions = 31,
    // PanelPositionArguments = 33,
    HotPanel = 34,
    Panel = 40,
    // PanelMenuChoosePosition = 41,
    // PanelMenuSummery = 42,
    PanelMenuShare = 43,
    // PanelStatisticsSummery = 44,
    // PanelStatisticsOpinionLeaders = 45,
    // PanelStatisticsRelatedDiscussions = 46,
    // Media = 52,
    MediaPanel = 53,
    MediaArgument = 54,
    UserProfile = 55,
    Blog = 58,
    BlogCategory = 59,
    Notifications = 60,
    // NotificationsOffers = 61,
    // NotificationsNewFollowers = 62,
    // NotificationsReplies = 63,
    // NotificationsNewLikes = 64,
    NotAllowedScreen = 65,
    InteractionsMessages = 67,
    Register = 70,
    RegisterAnonymously = 71,
    AlreadyRegistered = 72,
    Settings = 73,
    History = 74,
    LogIn = 75,
    LogOut = 76,
}
export enum ResourceStatusEnum {
    None = 0,
    Approved = 1 << 0,           //1      1
    Rejected = 1 << 1,           //2     10
    // Default = 1 << 2,         //4    100
    WaitingForApproval = 1 << 3, //8   1000
    Deleted = 1 << 4,            //16 10000
    Blocked = 1 << 5,
    Generated = 1 << 6
}
export enum ResourceEnum {
    Panels = 30,//p
    Positions = 31,//p
    Arguments = 33,//write//p
    PositionFeedback = 35,//p
    ArgumentFeedback = 36,//p
    Program = 37,
    Embedding = 38,
    Cluster = 39,
    Partner = 40,//creat
    Role = 41,
    ClusterPartner = 42,
    PartnerRequest = 43,
    Message = 44,
    Page = 47,
    Campaign = 48,
    Commerce = 49,
    // Email = 50,
    Statistics = 51,//create,write,approve
    UserProfile = 55,//p
    Users = 56,
    Blog = 58,
    Url = 59,
    BlogCategory = 60,
    NlpSessions = 65,
    Screen = 66,
}
export enum AuthorizationGroupEnum {
    None = 0,
    // AuthoizedUsers = 1,
    // ApprovedByAuthoized = 2,
    // ScoreValueMatching = 3,
    PartnerUsers = 4,
    Members = 5,
    // CustomGroups = 6,
    Users = 7,
    EveryOne = 8,
}
// [Flags]
export enum PermissionsEnum {
    None = 0,
    Create = 1 << 0,  // 1         1
    Read = 1 << 1,    // 2 -      10
    Write = 1 << 2,   // 4 -     100
    Delete = 1 << 3,  // 8 -    1000
    Approve = 1 << 4, // 16 -  10000 
    Watch = 1 << 5,   // 32 - 100000 
}
export enum PanelTypeEnum {
    None = 0,
    Normal = 1,
    Positions = 2,
    Opinion = 3,
    HeadToHead = 4
}
export enum MediaTypeEnum {
    Link = 1,
    Image = 2,
    Video = 3,
    Gif = 4,
    Audio = 5,
}
export enum MediaTagEnum {
    Humour = 1,
    Info = 2
}
export enum MediaServiceEnum {
    Youtube = 1,
    Giphy = 2,
    Vimeo = 3,
    Internal = 4,
}
export enum UserRequestStatusEnum {
    None = 0,
    Active = 1,
    Suspended = 2,
    Deleted = 3,
}
export enum UserRequestTypeEnum {
    None = 0,
    DeleteUser = 1,
    BeMember = 2
}
export enum OrderTypeEnum {
    None = 0,
    Date = 1,
    loadPage = 2,
}

export enum EmbeddingDisplayTypeEnum {
    Normal = 0,
    NoConnect = 1,
}

export enum PanelDisplayTypeEnum {
    Normal = 0,
    NoArgument = 1,
}