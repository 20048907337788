import { Injectable } from '@angular/core';
import { MessageModel } from '../models/message';
import { SearchUserArchiveRequest } from '../models/requests/searchUserArchiveRequest';
import { UserModel } from '../models/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ResourcesIdAndNameResponse, UsersResponse, MessagesResponse, UserResponse, BaseResponse, MessageResponse, IdResponse, UsersImagesResponse, UserSummaryResponse } from '../models/responses/Responses';
import { UserImageRequest } from '../models/requests/UserImageRequest';
import { UpdateStatusRequest } from '../models/requests/updateStatusRequest';

@Injectable({
    providedIn: 'root'
})

export class UserService {
    constructor(private api: ApiService) {
    }
    // GET
    SearchUserArchive(searchUserArchive: SearchUserArchiveRequest): Observable<ResourcesIdAndNameResponse> {
        return this.api.getRequest("User/SearchUserArchive", searchUserArchive).pipe(
            map(data => { return data; }));
    }
    GetUsersOfPartner(pageNumber: number, searchText?: string): Observable<UsersResponse> {
        return this.api.getRequest("User/GetUsersOfPartner", { pageNumber: pageNumber, searchText: searchText }).pipe(
            map(data => { return data; }));
    }
    GetRegisteredUsersOfPartner(PageNumber: number): Observable<ResourcesIdAndNameResponse> {
        return this.api.getRequest("User/GetRegisteredUsersOfPartner", { PageNumber: PageNumber }).pipe(
            map(data => { return data; }));
    }
    GetActiveUsersOfPartner(pageNumber: number): Observable<UsersResponse> {
        return this.api.getRequest("User/GetActiveUsersOfPartner", { pageNumber: pageNumber }).pipe(
            map(data => { return data; }));
    }
    GetMessagesOfPartner(pageNumber: number): Observable<MessagesResponse> {
        return this.api.getRequest("User/GetMessagesOfPartner", { pageNumber: pageNumber }).pipe(
            map(data => { return data; }));
    }
    SearchUserByEmail(email: string): Observable<IdResponse> {
        return this.api.getRequest("User/SearchUserByEmail", { email: email }).pipe(
            map(data => { return data; }));
    }
    GetUsersImages(userImageRequest: UserImageRequest): Observable<UsersImagesResponse> {
        return this.api.getRequest("User/GetUsersImages", userImageRequest).pipe(
            map(data => { return data; }));
    }
    GetUserByNickname(nickname: string): Observable<UserSummaryResponse> {
        return this.api.getRequest("User/GetUserByNickname", { nickname: nickname }).pipe(
            map(data => { return data; }));
    }
    //POST
    NewUser(user: UserModel): Observable<UserResponse> {
        return this.api.postRequest("User/NewUser", user).pipe(
            map(data => { return data; }));
    }
    EditUser(user: UserModel): Observable<UserResponse> {
        return this.api.postRequest("User/UpdateUser", user).pipe(
            map(data => { return data; }));
    }
    DeleteUserFromPartner(userId: number): Observable<BaseResponse> {
        return this.api.postRequest("User/DeleteUserFromPartner/" + userId, null).pipe(
            map(data => { return data; }));
    }
    NewMessage(message: MessageModel): Observable<MessageResponse> {
        return this.api.postRequest("User/NewMessage", message).pipe(
            map(data => { return data; }));
    }
    EditMessage(message: MessageModel): Observable<BaseResponse> {
        return this.api.postRequest("User/EditMessage", message).pipe(
            map(data => { return data; }));
    }
    DeleteMessage(messageId: number): Observable<BaseResponse> {
        return this.api.postRequest("User/DeleteMessage/" + messageId, null).pipe(
            map(data => { return data; }));
    }
    BanUserFromSite(userId: number): Observable<BaseResponse> {
        return this.api.postRequest("User/BanUserFromSite?id=" + userId, null).pipe(
            map(data => { return data; }));
    }
    UnbanUserFromSite(userId: number): Observable<BaseResponse> {
        return this.api.postRequest("User/UnbanUserFromSite?id=" + userId, null).pipe(
            map(data => { return data; }));
    }
    UpdateUserImageStatus(updateStatusRequest: UpdateStatusRequest): Observable<BaseResponse> {
        return this.api.postRequest("User/UpdateUserImageStatus", updateStatusRequest).pipe(
            map(data => { return data; }));
    }
    UpdateNicknameStatus(updateStatusRequest: UpdateStatusRequest): Observable<BaseResponse> {
        return this.api.postRequest("User/UpdateNicknameStatus", updateStatusRequest).pipe(
            map(data => { return data; }));
    }
    UpdateUserStatus(updateStatusRequest: UpdateStatusRequest): Observable<BaseResponse> {
        return this.api.postRequest("User/UpdateUserStatus", updateStatusRequest).pipe(
            map(data => { return data; }));
    }
}