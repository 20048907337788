import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbRequestPasswordComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { AuthService } from '../../../../services/auth.service';
import { Globals } from '../../../../services/globals';

declare var grecaptcha: any;

@Component({
  selector: 'ngx-request-password',
  templateUrl: './request-password.component.html',
  styleUrls: ['./request-password.component.scss']
})
export class RequestPasswordComponent extends NbRequestPasswordComponent implements OnInit {

  @ViewChild('recaptcha', { static: false }) recaptcha;

  constructor(
    private authService: AuthService,
    public globals: Globals,
    service: NbAuthService,
    @Inject(NB_AUTH_OPTIONS) options: {},
    cd: ChangeDetectorRef, router: Router
  ) {
    super(service, options, cd, router);
  }

  recaptchaToken: string = '';
  recaptchaValid = false;

  ngOnInit(): void {
    this.initRecaptchaScript();
  }
  forgotPassword() {
    this.authService.ForgotPassword(this.recaptchaToken, this.user.email).subscribe(res => { });
  }
  initRecaptchaScript() {
    this.executeRecaptcha();
  }
  reCaptchaCallback(response) {
      if (response !== '') {
        this.recaptchaValid = true;
        this.recaptchaToken = response;
      } else {
        this.recaptchaValid = false;
      }    
  };
  executeRecaptcha(isReset = false) {
    if (isReset) {
      grecaptcha.reset(this.recaptcha);
      return;
    }
    let siteKey = '6LerzyoaAAAAAATYhEJa0RhVfFlnsifB01cJC43w';
    this.recaptcha = grecaptcha.render('recaptcha', {
      'sitekey': siteKey,
      'callback': this.reCaptchaCallback.bind(this),
      'theme': 'light'
    });
  }
  resetCaptcha() {
    this.recaptchaValid = false;
    this.executeRecaptcha(true);
  }
}
