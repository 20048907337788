import { Injectable } from '@angular/core';
import { AuthTypeEnum, DisplayingTypeEnum } from '../models/enums';
import { CodeVerificationRequest } from '../models/requests/codeVerificationRequest';
import { PhoneAuthenticationRequest } from '../models/requests/phoneAuthenticationRequest';
import { RegisterByEmailRequest } from '../models/requests/registerByEmailRequest';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { Globals } from './globals';
import { UserSettingsModel } from '../models/userSettings';
import { AuthResponse, BaseResponse, UrlResponse } from '../models/responses/Responses';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(private api: ApiService, private globals: Globals) {
  }
  // GET
  Token(displayingType: DisplayingTypeEnum, countryId: number, defaultLanguage: string, nickName: string, imageUrl: string): Observable<AuthResponse> {
    return this.api.getRequest("Auth/Token", { DisplayingType: displayingType, CountryId: countryId, DefaultLanguage: defaultLanguage, Nickname: nickName, ImageUrl: imageUrl }).pipe(
      map(data => { return data; }));
  }
  //POST
  AuthenticatePhone(phoneRequest: PhoneAuthenticationRequest): Observable<BaseResponse> {
    return this.api.postRequest("Auth/AuthenticatePhone", phoneRequest).pipe(
      map(data => { return data; }));
  }

  LoginByEmail(registerByEmail: RegisterByEmailRequest): Observable<AuthResponse> {
    return this.api.postRequest("Auth/LoginByEmail", registerByEmail).pipe(
      map(data => { return data; }));
  }
  ForgotPassword(recaptchaToken: string, login: string): Observable<UrlResponse> {
    return this.api.postRequest("Auth/ForgotPassword", { Login: login, CaptchaToken: recaptchaToken }).pipe(
      map(data => { return data; }));
  }
  NewPassword(code: number, userId: number, newPassword: string, recaptchaToken: string): Observable<UrlResponse> {
    let body = { Code: code, UserId: userId, NewPasswordString: newPassword, ReCaptchaToken: recaptchaToken }
    return this.api.postRequest("Auth/NewPassword", body).pipe(
      map(data => { return data; }));
  }
  IsEmailVerificationCodeValid(code: number, userId: number): Observable<AuthResponse> {
    return this.api.postRequest("Auth/IsEmailVerificationCodeValid?code=" + code + '&userId=' + userId, null).pipe(
      map(data => { return data; }));
  }
  register3rdPartyUser(token: string, supplier: AuthTypeEnum, redirectUrl: string): Observable<AuthResponse> {
    var params = { 'AuthenticationSupplier': supplier, 'Token': token, 'CountryId': this.globals.defaultCountryId, 'partnerId': this.globals.settings.partnerId, 'ProgramId': this.globals.settings.programId, 'RedirectUrl': redirectUrl };
    return this.api.postRequest("Auth/Register3rdPartyUser", params);
  }
  updateSettings(userSettings: UserSettingsModel): Observable<AuthResponse> {
    return this.api.postRequest("Auth/UpdateSettings", userSettings).pipe(
      map(data => { return data; }));
  }
}