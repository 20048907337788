<div *ngIf="isLoadView">
  <h1 id="title" class="title">{{globals.Resources.get('ChangePassword')}}</h1>
  <p class="sub-title">{{globals.Resources.get('ChangePasswordTitle')}}</p>

  <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Hooray!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
  </nb-alert>

  <form (ngSubmit)="resetPass()" #resetPassForm="ngForm" aria-labelledby="title">

    <div class="form-control-group">
      <label class="label" for="input-password">{{globals.Resources.get('NewPassword')}}</label>
      <input nbInput [(ngModel)]="newPass" #password="ngModel" type="password" id="input-password" name="password"
        class="first" [placeholder]="globals.Resources.get('NewPassword')" autofocus fullWidth fieldSize="large"
        [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
        required minlength="6" maxlength="12"
        pattern="^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9!@#$%^&?]+)$"
        [attr.aria-invalid]="password.invalid && password.touched ? true : null">
      <ng-container *ngIf="password.invalid && password.touched">
        <p class="caption status-danger" *ngIf="password.errors?.required">
          {{globals.Resources.get('RequiredPasswordMessage')}}
        </p>
        <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">
          {{globals.Resources.get('PasswordValidationMessage')}}
        </p>
        <p class="caption status-danger" *ngIf="password.errors?.pattern">
          {{globals.Resources.get('PasswordCharactersMessage')}}
        </p>
      </ng-container>
    </div>

    <div class="form-group">
      <label class="label" for="input-re-password">{{globals.Resources.get('ConfirmPassword')}}</label>
      <input nbInput [(ngModel)]="approvePassword" #rePass="ngModel" id="input-re-password" name="rePass"
        type="password" class="last" [placeholder]="globals.Resources.get('ConfirmPassword') " fullWidth
        fieldSize="large" [status]="rePass.touched
               ? (rePass.invalid || password.value != rePass.value ? 'danger' : 'success')
               : 'basic'" required
        [attr.aria-invalid]="rePass.invalid && rePass.touched ? true : null">
      <ng-container *ngIf="rePass.touched">
        <p class="caption status-danger" *ngIf="rePass.invalid && rePass.errors?.required">
          {{globals.Resources.get('RequiredConfirmPasswordMessage')}}
        </p>
        <p class="caption status-danger" *ngIf="password.value != rePass.value && !rePass.errors?.required">
          {{globals.Resources.get('ValidatePasswordsMessage')}}
        </p>
      </ng-container>
    </div>

    <div class="recaptcha-v2" id="recaptcha1"></div>

    <button nbButton status="primary" fullWidth size="large"
      [disabled]="submitted || !resetPassForm.valid || !recaptchaValid" [class.btn-pulse]="submitted"
      (click)="newPassword()">
      {{globals.Resources.get('ChangePassword')}}
    </button>
  </form>

  <section class="sign-in-or-up" aria-label="Sign in or sign up">
    <p><a class="text-link" routerLink="../login">Back to Log In</a></p>
    <p><a class="text-link" routerLink="../register">Register</a></p>
  </section>

</div>