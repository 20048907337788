import { DOCUMENT } from '@angular/common';
import { IfStmt } from '@angular/compiler';
import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbLoginComponent, NbRequestPasswordComponent, NB_AUTH_OPTIONS } from '@nebular/auth';
import { RegisterByEmailRequest } from '../../../../models/requests/registerByEmailRequest';
import { UserModel } from '../../../../models/user';
import { AuthService } from '../../../../services/auth.service';
import { Globals } from '../../../../services/globals';
import { PartnerService } from '../../../../services/partner.service';

declare var grecaptcha: any;
declare var recaptcha: any;
@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends NbLoginComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private partnerService: PartnerService,
    public globals: Globals,
    service: NbAuthService,
    @Inject(DOCUMENT) document: any,
    @Inject(NB_AUTH_OPTIONS) options: {},
    cd: ChangeDetectorRef, router: Router) {
    super(service, options, cd, router);
  }
  user: UserModel = new UserModel();
  register: RegisterByEmailRequest = new RegisterByEmailRequest();
  wrongPassword: boolean = false;
  googleImgUrl: string = 'https://app.say-app.com/sumit/assets/images/';
  error: any;
  googleAuthKey = '156034241302-9lc8jmamlsp350oml582rbqovhnqneh6.apps.googleusercontent.com';
  googleplus_redirect_url;
  recaptchaToken: string;
  isForgotPassword: boolean = false;
  isNewPassword: boolean = false;
  recaptchaValid: boolean = false;
  isShowReacptchaMessage: boolean = false;

  ngOnInit(): void {
    this.loadRercaptcha();
    if (document.location.href.includes('sumit_code'))
      this.isNewPassword = true;
    else {
      if (this.globals.isUserLoggedIn()) {
        this.router.navigate(['sumit-pages/admin/management/statistics'])
      }
      this.googleplus_redirect_url = window.location.protocol + '//' + window.location.host + window.location.pathname;
      let googelCode = this.getUrlParameter('code');
      if (googelCode) {
        this.authenticate(googelCode);
      }
    }
  }

  reCaptchaCallback(response) {
    if (response !== '') {
      this.recaptchaValid = true;
      this.isShowReacptchaMessage = false;
      this.recaptchaToken = response;
    } else {
      this.recaptchaValid = false;
    }
  };
  executeRecaptcha(isReset = false) {
    if (isReset) {
      grecaptcha.reset(recaptcha);
      return;
    }
    let siteKey = '6LerzyoaAAAAAATYhEJa0RhVfFlnsifB01cJC43w';
    setTimeout(() => {
      recaptcha = grecaptcha.render('recaptcha2', {
        'sitekey': siteKey,
        'callback': this.reCaptchaCallback.bind(this),
        'theme': 'light'
      });
    }, 2000);
  }
  resetCaptcha() {
    this.recaptchaValid = false;
    this.executeRecaptcha(true);
  }
  login() {
    this.globals.settings.isLoaderVisible = true;
    this.register.Email = this.user.Email;
    this.register.Password = this.user.Password;
    this.register.CaptchaToken = this.recaptchaToken;
    if (this.recaptchaToken && this.recaptchaToken != '') {
      this.authService.LoginByEmail(this.register).subscribe(res => {
        this.resetCaptcha();
        if (res.User) {
          if (res.ResponseStatus.MessageCode.includes('.Success')) {
            this.globals.setCurrentUser(res.User);
            this.partnerService.GetPartner().subscribe(data => {
              this.globals.currentPartner = data.Partner;
              this.partnerService.GetPartnersOfUser().subscribe(data => {
                res.User.Partners = data.Resources;
                this.globals.setCurrentUser(res.User);
                this.globals.settings.isLoaderVisible = false;
                this.router.navigate(['sumit-pages/admin/management/statistics']);
              })
            });
          }
        }
        else if (res.ResponseStatus.Status == 414) {
          this.wrongPassword = true;
          this.globals.settings.isLoaderVisible = false;
        }
        else if (res.ResponseStatus.Status < 200 || res.ResponseStatus.Status > 399)
          this.globals.settings.isLoaderVisible = false;
      });
    }
    else {
      this.globals.settings.isLoaderVisible = false;
      this.isShowReacptchaMessage = true;
    }
  }
  forgotPassword() {
    this.authService.ForgotPassword(this.recaptchaToken, this.user.Email).subscribe(res => { });
  }
  getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
      }
    }
  };
  authenticate(code: any) {
    this.globals.settings.isLoaderVisible = true;
    this.authService.register3rdPartyUser(code, 103, this.googleplus_redirect_url).subscribe(res => {
      if (res.User) {
        if (res.ResponseStatus.MessageCode.includes('.Success')) {
          this.globals.setCurrentUser(res.User);
          this.partnerService.GetPartner().subscribe(data => {
            this.globals.currentPartner = data.Partner;
            this.partnerService.GetPartnersOfUser().subscribe(data => {
              res.User.Partners = data.Resources;
              this.globals.setCurrentUser(res.User);
              this.globals.settings.isLoaderVisible = false;
              this.router.navigate(['sumit-pages/admin/management/statistics']);
            })
          });
        }
      }
      else
        this.globals.settings.isLoaderVisible = false;
    })
  }
  openGoogleLogin() {
    var Googleurl = "https://accounts.google.com/o/oauth2/auth?response_type=code&redirect_uri="
      + this.googleplus_redirect_url
      + "&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile&client_id="
      + this.googleAuthKey;
    window.location.replace(Googleurl);
  }
  loadRercaptcha() {
    var captchaScript = document.createElement('script');
    captchaScript.onload = () => {
      setTimeout(() => {
        this.executeRecaptcha();
      }, 1000);
    };
    captchaScript.src = "https://www.google.com/recaptcha/api.js";
    document.head.appendChild(captchaScript);
  }
}