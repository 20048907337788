<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <!-- <a class="logo" href="#" (click)="navigateHome()">ngx-<span>admin</span></a> -->
    <!-- <a class="logo" href="#" ><span>SUMIT</span></a> -->
    <a href="#"><img src="/assets/images/logo.png" style="width: 120px;"></a>
  </div>
  <nb-select class="theme" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
  <nb-select class="partnersList" [selected]="currentPartner" *ngIf="partnersOfUser && partnersOfUser.length>1"
    (selectedChange)="changePartner($event)" [placeholder]="globals.Resources.get('ChoosePartner')"
    style="float: right;">
    <nb-option *ngFor="let partner of partnersOfUser" [value]="partner.ResourceName">{{partner.ResourceName}}
    </nb-option>
  </nb-select>
  <nb-select [placeholder]="globals.Resources.get('Language')" name="Language" [selected]="globals.language"
    (selectedChange)="changeLanguage($event)">
    <nb-option value="en-US">{{globals.Resources.get('English')}}</nb-option>
    <nb-option value="he-IL">{{globals.Resources.get('Hebrew')}}</nb-option>
  </nb-select>
</div>

<div class="header-container">
  <nb-actions size="small">
    <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>
    <nb-action class="control-item" icon="email-outline"></nb-action>
    <nb-action class="control-item" icon="bell-outline"></nb-action>
    <nb-action class="user-action" *nbIsGranted="['view', 'user']">
      <!-- [picture]="user?.picture" -->
      <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.NickName" [picture]="picture">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>