import { AuthTypeEnum, UserPriorityEnum, InteractionEnum, ResourceStatusEnum } from './enums'
import { ResourceIdAndNameModel } from './resourceIdAndNameModel'
import { UserSettingsModel } from './userSettings'

export class UserModel {
    Id?: number
    Username?: string
    FirstName?: string
    LastName?: string
    Created?: Date
    MainPhoneNumber?: string
    Registered?: Date
    Email: string
    NickName: string
    AvatarUrl?: string
    CountryId?: number
    UserToken?: string
    AuthType: AuthTypeEnum = 101;
    Password: string
    ProgramId?: number
    PartnerId?: number
    priority?: UserPriorityEnum
    Interaction?: InteractionEnum
    Settings?: UserSettingsModel
    UserStatus: ResourceStatusEnum = 1;
    Roles: string = '';
    Partners: ResourceIdAndNameModel[];
    IsAdmin: boolean;
    DefaultLanguage: string;
}
