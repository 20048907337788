import { Injectable } from '@angular/core';
import { LocaleResources } from '../models/localeResources';
import { ApiService } from './api.service';
import { Globals } from './globals';

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {
    constructor(private api: ApiService, private globals: Globals) {
    }
    async getResources() {
        const data = await this.api.getRequestWitheoutUserLogin("Resources/Get", { "locale": this.globals.getCurrentUserLanguage() }).toPromise();
        var resourcesForStorage = { lastUpdatesTime: new Date(), resources: data }
        this.globals.setItemInStorage(this.globals.appLocalResourcesKey, resourcesForStorage);
        let localeResources: LocaleResources = new LocaleResources(data);
        return localeResources;
    }

    async getResourcesSync() {
        let localeData = this.globals.getItemFromStorage(this.globals.appLocalResourcesKey);
        var diffInMinutes: number = 1;
        if (localeData && localeData.lastUpdatesTime) {
            let diffInMs: number = (new Date()).valueOf() - new Date(localeData.lastUpdatesTime).valueOf();
            diffInMinutes = Math.round(diffInMs / 1000 / 60);
        }
        if (localeData && diffInMinutes < 1) {
            let resources: LocaleResources = new LocaleResources(localeData.resources);
            return resources;
        }
        if (!localeData) {
            localeData = await this.getResources();
        }
        const localeResources: LocaleResources = new LocaleResources(localeData.resources);
        return localeResources;
    }

    // resourcesVariables={
    //     campaignTitle:this.globals.localeResources.get('CampaignManagemant'),
    // }
}
