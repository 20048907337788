<ng-container *ngIf="globals.settings.isLoaderVisible">
  <div class="sumit-loader-wrapper">
    <div class="sumit-loader-wrapper-fixed" style.max-height="inherit" style.max-width="inherit">
      <div class="sumit-loader-opacity"></div>
      <div class="sumit-loader">Loading...</div>
    </div>
  </div>
</ng-container>
<div *ngIf="!isNewPassword && !isForgotPassword ">
  <h1 id="title" class="title">{{globals.Resources.get('SignIn')}}</h1>

  <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
    <p class="alert-title"><b>Oh snap!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
  </nb-alert>

  <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
    <p class="alert-title"><b>Hooray!</b></p>
    <ul class="alert-message-list">
      <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
  </nb-alert>

  <form (ngSubmit)="login()" #form="ngForm" aria-labelledby="title">

    <div class="form-control-group">
      <label class="label" for="input-email">{{globals.Resources.get('EmailAddress')}}:</label>
      <input nbInput fullWidth [(ngModel)]="user.Email" #email="ngModel" name="email" id="input-email"
        pattern=".+@.+\..+" [placeholder]="globals.Resources.get('EmailAddress')" autofocus
        [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'" required
        [attr.aria-invalid]="email.invalid && email.touched ? true : null">
      <ng-container *ngIf="email.invalid && email.touched">
        <p class="caption status-danger" *ngIf="email.errors?.required">
          {{globals.Resources.get('RequiredEmailMessage')}}
        </p>
        <p class="caption status-danger" *ngIf="email.errors?.pattern">
          {{globals.Resources.get('RightEmailMessage')}}
        </p>
      </ng-container>
    </div>

    <div class="form-control-group">
      <label class="label" for="input-password">{{globals.Resources.get('Password')}}:</label>
      <input nbInput fullWidth [(ngModel)]="user.Password" #password="ngModel" name="password" type="password"
        id="input-password" [placeholder]="globals.Resources.get('Password')"
        [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'" minlength="6" maxlength="12"
        required [attr.aria-invalid]="password.invalid && password.touched ? true : null">
      <ng-container *ngIf="password.invalid && password.touched || wrongPassword ">
        <p class="caption status-danger" *ngIf="password.errors?.required">
          {{globals.Resources.get('RequiredPasswordMessage')}}
        </p>
        <p class="caption status-danger" *ngIf="(password.errors?.minlength || password.errors?.maxlength)">
          <!-- Password should contains
        from {{ getConfigValue('forms.validation.password.minLength') }}
        to {{ getConfigValue('forms.validation.password.maxLength') }}
        characters -->
          {{globals.Resources.get('PasswordValidationMessage')}}
        </p>
      </ng-container>
    </div>

    <div class="recaptcha-v2" id="recaptcha2"></div>
    <p class="caption status-danger" *ngIf="isShowReacptchaMessage">
      {{globals.Resources.get('RecaptchaMessage')}}
    </p>

    <div class="form-control-group accept-group">
      <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">
        {{globals.Resources.get('RememberMe')}}</nb-checkbox>
      <!-- <a class="forgot-password" (click)="forgotPassword()">{{globals.Resources.get('ForgotPassword')}}</a> -->
      <a class="forgot-password" (click)="isForgotPassword=true">{{globals.Resources.get('ForgotPassword')}}</a>
    </div>

    <button nbButton fullWidth status="success" [disabled]="submitted || !form.valid" [class.btn-pulse]="submitted">
      {{globals.Resources.get('SignIn')}}
    </button>
  </form>

  <section *ngIf="socialLinks && socialLinks.length > 0" class="links" aria-label="Social sign in">
    {{globals.Resources.get('OrConectWith')}}
    <!-- <div class="icon" (click)="authenticate()"> -->
    <div class="icon" (click)="openGoogleLogin()">
      <img height="25" width="25" class="logo" [src]="googleImgUrl+'google.svg'">
    </div>
    <!-- <div class="socials">
    <ng-container *ngFor="let socialLink of socialLinks">
      <a *ngIf="socialLink.link"
         [routerLink]="socialLink.link"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
      <a *ngIf="socialLink.url"
         [attr.href]="socialLink.url"
         [attr.target]="socialLink.target"
         [attr.class]="socialLink.icon"
         [class.with-icon]="socialLink.icon">{{ socialLink.title }}</a>
    </ng-container>
  </div> -->
  </section>

  <section class="another-action" aria-label="Register">
    {{globals.Resources.get('AccountMessage')}}
    <!-- <a class="text-link" routerLink="../register">Sign Up</a> -->
    <a class="text-link">{{globals.Resources.get('SignUp')}}</a>
  </section>
</div>

<ngx-request-password *ngIf="isForgotPassword"></ngx-request-password>
<ngx-forgot-password *ngIf="isNewPassword"></ngx-forgot-password>